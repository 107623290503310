import React, { Component } from "react"
import { graphql } from "gatsby"
import Tracks from "../components/tracks.js"
import Product from "../components/product.js"
import Layout from "../components/layout"
import ReleaseHeader from "../components/releaseHeader"
import axios from "axios"
import ContextConsumer from "../services/context"
import CartSuccessMessage from '../components/cartSuccessMessage.js'
import messageStyle from '../components/cartSuccessMessage.module.css'
import posed from 'react-pose'
import { getToken } from "../utils/getToken"

class Release extends Component {

  state={
    trackProducts: [],
    trackProductsFetched: false,
    ochreCartReady: false,
    productAddedToBasket: [],
    releaseDescription: '',
    cartURL: '#'
  }

  populateReleaseProductList(releaseProductIds) {
    window.buy.products('.ochre-release-product-list-container', {style: 'mini'}).get(releaseProductIds);
  }

  fetchProducts(releaseId, options) {
    let releaseProductIds = []
    let trackProductIds = []
    let trackProducts=[]
    let description
    axios 
      .get('https://api.ochre.io/v1/retail/products?reference_type=release&reference_id=' + releaseId , options)
      .then(response => {
        response.data.results.map( product => {
          if (product.type == 'release') {
            description = product.group.description
            releaseProductIds.push(product.id)
          } else {
            trackProductIds.push(product.id)
            trackProducts.push(product)
          }
        })
      }).then(response => {
        this.populateReleaseProductList(releaseProductIds)
        this.setState({
          trackProducts: trackProducts,
          trackProductsFetched: true,
          releaseDescription: description
        })
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }

  productAddedToBasket(item) {
    const obj = {
      title: item.product.title,
      visible: true
    }
    const {productAddedToBasket} = this.state
    const newArr = productAddedToBasket.concat(obj)
    const messageTotal = productAddedToBasket.length
    const cartUrl = typeof window !== `undefined` ? window.buy.getCheckoutURL() : ''
    this.setState({ cartURL: cartUrl })
    this.setState({ productAddedToBasket: newArr })
    
    setInterval(() => {
      const i = messageTotal
      this.setState(prevState => {
        let obj = Object.assign({}, prevState.productAddedToBasket);  // creating copy of state variable jasper
        obj[i].visible = false;                     // update the name property, assign a new value                 
        return { obj };                                 // return new object jasper object
      })
    }, 3000);
  }
  


  listenForCartAdd() {
    let oldXHROpen = window.XMLHttpRequest.prototype.open;
    const component = this
    window.XMLHttpRequest.prototype.open = function(method, url) {
      if(url.includes("/cart/embed")) {
        this.addEventListener('load', function() {
          // do something with the response text
           var responseObject = JSON.parse(this.responseText);
           console.log('ochre cart loaded');
         });
      }
      if(url.includes("/cart/embed/items")) {
        this.addEventListener('load', function() {
          // do something with the response text
          var responseObject = JSON.parse(this.responseText);
          if ( responseObject.price ) {
            console.log(responseObject)
            component.productAddedToBasket(responseObject)
          }
        });
      }
     //let the request carry on normally in the end
     return oldXHROpen.apply(this, arguments);
    };
  }
 
  componentDidMount() {

    const data = this.props.data
    const products = data.allProducts.edges
    const releaseId = data.allReleases.edges[0].node.releaseId
    const releaseBuyButtonId = parseInt(releaseId)
    getToken().then(options => {
      this.fetchProducts(releaseId, options);
    }) 
    
    this.listenForCartAdd()
    


  }
  
  render () {
    const release = this.props.data.allReleases.edges[0].node
    const products = this.state.trackProducts

    // const allProducts = products.map( product => {
    //   return (
    //     <Product 
    //       key={product.node.id}
    //       product={product.node}
    //     />
    //   )
    // })

    let productDescription;
    productDescription = this.state.releaseDescription


    const releaseHeader = <ReleaseHeader release={release} />
    let tracksEl = <div></div>
    
    


   


    if (this.state.trackProductsFetched) {
      tracksEl = <Tracks 
        releaseId={release.releaseId}
        trackProducts={this.state.trackProducts}
        ochreCartReady={this.state.ochreCartReady}
        cartScript={this.props.cartScript}
      />
    }

    let top
    const winInnerWidth = typeof window !== `undefined` ? window.innerWidth : null
    if ( winInnerWidth < 640 ) {
      top = '75px'
    } else { top = 0 }
    const Message = posed.div({
      hidden: { top: '-200px' },
      visible: { 
        top: top,
        transition: { duration: 1000 },
      }
    })
    

    return (
      <>

        {this.state.productAddedToBasket.map( (item, index) => {
          const isVisible = item.visible
          return(
            <Message className="fixed w-full z-10" pose={isVisible ? 'visible' : 'hidden'}>
              <div className={`message overflow-hidden w-full p-4 border border-l-0 border-black border-r-0 border-t-0 border-b-1 bg-white`}>
              <div className="inline-block">
                <span className="opacity-80"> Product added to your basket</span>
              </div>
              <div className="inline-block ml-4">
                <a href={this.state.cartURL} target="_blank" className="border border-black rounded-full btn-padding-y px-4 hover:bg-k7green2 cursor-pointer">
                  Your Basket
                </a>
              </div>
              </div>
            </Message>
          )
        })}

        <div className="mt-6 md:mt-0 lg:mt-0 xl:mt-0">
          {releaseHeader}
        </div>


        <section className="bg-white flex flex-col md:flex-row lg:flex-row xl:flex-row px-4 md:px-6 lg:px-6 xl:px-6">
          <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 pr-6 order-2 md:order-1 lg:order-1 xl:order-1">
            <div className="p-8 md:p-12 lg:p-12 xl:p-12">
              {productDescription}
            </div>
            <div className="">
              {tracksEl}
            </div>
          </div>

          <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 order-1 md:order-2 lg:order-2 xl:order-2">
            <div className="ochre-release-product-list-container"></div>
          </div>

        </section>
  
      </>
    )

  }
  
}

export default Release

export const query = graphql`
query($title: String!) {
  allReleases(filter: {title: {eq: $title}}) {
    edges {
      node {
        title
        artist {
          name
          id
        }
        catNumber
        formats {
          code
          distribution
          family
          variant
        }
        id
        imageUrl
        releaseDate
        releaseId
        trackCount
        type
        totalLength
        images {
          size
          url
          width
        }
        label {
          id
          name
        }
        catalog {
          id
          name
        }
      }
    }
  }
  
  allProducts(filter: {references: {elemMatch: {resource: {title: {eq: $title}}}}}) {
    edges {
      node {
        title
        price {
          value
          discount
        }
        format
        productId
        dateAvailable
        distribution
        catNumber
        id
        type
        universe
        upc
        variant
        group {
          id
        }
      }
    }
  }
}
`
Release.contextType = ContextConsumer;
