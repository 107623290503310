import React, { Component } from "react"
import axios from "axios"
import { getToken } from "../utils/getToken"


class Product extends Component {
  state = {
    loading: true,
    error: false,
    availability: false
  }


  componentDidMount() {
    getToken()
    this.fetchAvailability()
  }

  render() {
    let content;
    if (this.state.loading) {
      content = <div>LOADING</div>
    } else {
      content = <div>FETCHED</div>
    }

    let button
    if (this.state.availability) {
      button = <div>BUY</div>
    } else {
      button = <div>OUT OF STOCK</div>
    }

    const product = this.props.product

    return (
      <div key={product.productId}>
        {content}
        <div>
          Fomat: {product.format}
          <br/>
          Variant: {product.variant}
          <br/>
          Variant: {product.price.value}
          <br/>
          {button}
          <br/>
          <br/>
        </div>
      </div>
    )

  }

 
  fetchAvailability = async() => {
    const token = sessionStorage.getItem("token")
    const productId = this.props.product.productId
    const options = {
      headers: { 'Authorization': 'Bearer ' + token }
    }

    axios 
      .get('https://api.ochre.io/v1/retail/products/' + productId + '/availability', options)
      .then(response => {
        this.setState({
          loading: false,
          availability: response.data.inStock
        })
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }


}
export default Product
