import React, { Component } from "react"
import HeaderBackgroundImage from "./headerBackgroundImages"
import { fetchMediaItems } from "../utils/fetchMediaItems"
import ContextConsumer from "../services/context"

class ReleaseHeader extends Component {

  playTrack() {
    const id = this.props.release.releaseId
    fetchMediaItems(id, 'release').then(results => {
      this.context.data.loadTracksAndPlay( results )
    })
  }


  render() {
    const release = this.props.release
    const artist = release.artist

    return (

      <section className="flex flex-col md:flex-row lg:flex-row xl:flex-row px-4 md:px-6 lg:px-6 xl:px-6">
        <div className="order-2 md:order-1 lg:order-1 xl:order-1 w-full md:w-4/8 lg:w-4/8 xl:w-4/8 self-center uppercase">
          <div className="hidden md:block lg:block xl:block w-1/5 border border-b-0 text-center border-black mono-small bg-white p-1 w-auto">
            {release.catNumber}
          </div>
          <div className="block text-3xl md:text-4xl lg:text-4xl xl:text-4xl border border-b-0 md:border-b lg:border-b xl:border-b md:border-r-0 lg:border-r-0 xl:border-r-0 border-black p-6 md:p-8 xl:p-8 lg:p-8 bg-k7green">
            <div className="leading-none">
              {release.title}
            </div>
            <div className="opacity-75 leading-none">
              {artist.name}
            </div>              
          </div>
          <div onClick={() => { this.playTrack() }} className="hidden md:block lg:block xl:block w-4/8 border border-t-0 border-black hover:bg-k7green2 cursor-pointer uppercase bg-white p-2 px-4 w-auto">
            <span className="pr-2">►</span>
            Listen
          </div>
        </div>

        <div className="order-1 md:order-2 lg:order-2 xl:order-2 w-full md:w-4/8 lg:w-4/8 xl:w-4/8 border border-black border-b-0 p-6 bg-white">
          <img className="w-full" src={release.images[4].url} />
        </div>

        <HeaderBackgroundImage bgImgUrl={release.images[0].url} />
      </section>
    )
  }
}
ReleaseHeader.contextType = ContextConsumer;
export default ReleaseHeader
