import React, { Component } from "react"


class headerBackgroundImage extends Component {

  componentDidMount() {

  }

  render() {
    const bgImgUrl = this.props.bgImgUrl
    const backgroundImageCx="fixed opacity-25 bgBlur"
    const backgroundImageStyle= {
      backgroundImage: "url(" + bgImgUrl +")",
      backgroundSize: "cover",
      width: '120%',
      height: '120%',
      top: '-20%',
      left: '-20%',
      zIndex: -10,
    }


    return (
      <div className={backgroundImageCx} style={backgroundImageStyle}></div>
    )

  }
}

export default headerBackgroundImage
