import React, { Component } from "react"
import axios from "axios"
import { getToken } from "../utils/getToken"
import Track from './track'


class Tracks extends Component {
  state = {
    loading: true,
    error: false,
    tracks: []
  }


  componentDidMount() {
    getToken().then(options => {
      this.fetchContentItems(options)
    })
  }

  render() {

    return (
      <div>
        {this.state.tracks.map( (item, index) => {

          // Find the Products from list of all Release Track Products 
          const filteredProducts = this.props.trackProducts.filter(it => it.title.includes(item.title))
          return(
            <Track
              index={index}
              key={item.id}
              track={item}
              products={filteredProducts}
              ochreCartReady={this.props.ochreCartReady}
              cartScript={this.props.cartScript}
            />
          )
        })}

      </div>
    )

  }

 
  fetchContentItems = async(options) => {
    const token = sessionStorage.getItem("token")
    const releaseId = this.props.releaseId

    axios 
      .get('https://api.ochre.io/v1/music/releases/' + releaseId + '/tracks', options)
      .then(response => {
        this.setState({
          loading: false,
          tracks: response.data.results
        })
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }


}
export default Tracks
